import React from 'react'
import { CheckboxTicked, CheckboxUnticked } from 'svg'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'

const LabeledCheckbox = ({ label, textStyle, labelStyle, ...props }) => {
  return (
    <div>
      <FormControlLabel
        style={{ marginLeft: '-4px', ...labelStyle }}
        control={<Checkbox {...props} checkedIcon={CheckboxTicked} icon={CheckboxUnticked} />}
        label={<Typography style={{ fontSize: '14px', fontWeight: '600', marginLeft: '2px', color: '#393947', ...textStyle }}>{label}</Typography>} />
    </div>
  )
}
export default LabeledCheckbox