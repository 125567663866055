import React, { Component } from 'react'
import { ControlledForm, PageContainer, LabeledCheckbox } from 'components'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core'
import { BackToTop, LaybuyHeart } from 'svg'
import CloseIcon from '@material-ui/icons/Close'
import styles from './Form.module.css'
import qs from 'qs'

export default class Form extends Component {
  state = { }

  handleCheckChange = ({ target: { name, checked } }) => {
    const newState = {
      ...this.props.formData,
      [name]: checked,
      ...(checked && { unsubMaster: false, unsubReason: null })
    }
    this.props.onFormDataChange(newState)
    this.clearBanners()
  }

  clearBanners = () => {
    if (this.state.errorBanner || this.state.successBanner) {
      this.setState({ errorBanner: false, successBanner: false, successMessage: undefined, errorMessage: undefined })
    }
  }

  get individualFields() {
    return this.props.sections.flatMap(({fields}) => fields.map(f => f.name))
  }

  handleUnsubMasterChecked = ({ target: { name, checked } }) => {
    const newState = {
      ...this.props.formData,
      [name]: checked,
      ...(checked ? this.individualFields.reduce((memo, key) => ({ ...memo, [key]: false }), {}) : {}),
      errorBanner: false, successBanner: false
    }
    this.props.onFormDataChange(newState)
    this.clearBanners()
  }

  handleSave = async (saveAction) => {
    this.clearBanners()

    if (this.props.formData.unsubMaster || this.individualFields.every(f => !this.props.formData[f])) {
      this.setState({ unsubDialog: 'ask' })
      await new Promise(r => this.unsubReasonResolve = r)
    }

    const result = await this.props.onSave(saveAction, { onSaveRedirect: false })
    const successBanner = (result?.type === this.props.modelType)
    this.setState({ successBanner, errorBanner: !successBanner })
    window.scrollTo(0, 0)
  }

  setUnsubDialogState = (unsubDialog, unsubReason) => () => {
    if (this.state.unsubDialog === 'ask') {
      this.props.onFormDataChange({...this.props.formData, unsubReason: (unsubReason || null)})
    }
    if (!unsubDialog || unsubDialog === 'thank') {
      this.unsubReasonResolve && this.unsubReasonResolve()
      this.unsubReasonResolve = undefined
    }
    this.setState({unsubDialog})
  }

  unsubReasons = [
    "Too many communications",
    "They're not relevant to me",
    "They're inappropriate",
    "I just don't like them",
    "No reason",
  ]

  renderUnsubDialog = () =>
    <Dialog open={!!this.state.unsubDialog} className={styles.dialog}>
      <DialogTitle className={styles.dialogTitle}>
        <IconButton key="close" aria-label="Close" style={{ position: 'absolute', left: '0px', top: '-3px', color: '#9595A3' }} onClick={this.setUnsubDialogState(false)}>
          <CloseIcon />
        </IconButton>
        <div style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', padding: '8px', borderBottom: '0.5px solid #D4D4DC' }}>
          Unsubscribe All
        </div>
      </DialogTitle>
      {this.state.unsubDialog === 'ask' && <DialogContent className={styles.dialogContent}>
        <div className={styles.dialogTextPrimary}>
          Looks like you don't want Laybuy comms
        </div>
        <div className={styles.dialogTextSecondary}>
          Care to tell us why?
        </div>
        <div>
          {this.unsubReasons.map((reason, i) =>
            <div key={i}><Button className={styles.dialogButton} onClick={this.setUnsubDialogState('thank', reason)}>{reason}</Button></div>
          )}
        </div>
      </DialogContent>}
      {this.state.unsubDialog === 'thank' && <DialogContent className={styles.dialogContent}>
        <div className={styles.dialogTextPrimary}>
          Thanks!
        </div>
        <div className={styles.dialogTextSecondary}>
          Don't forget you can resubscribe anytime.
        </div>
        <div>
          <div><Button className={styles.dialogButton} onClick={this.setUnsubDialogState(false)}>Close</Button></div>
        </div>
      </DialogContent>}
      <DialogActions></DialogActions>
    </Dialog>

  handleSendLinkClick = async () => {
    this.clearBanners()
    const { email, signature, timestamp } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    try {
      await this.props.preferencesContext.actions.sendLink({email, signature, timestamp})
      this.setState({ successBanner: true, successMessage: `New link sent to ${email}` })
    } catch (error) {
      console.log(error)
      this.setState({ errorBanner: true })
    }
  }

  renderFinePrint = () => <div className={styles.finePrint}>
    Any changes made to the above preferences are reflective of marketing communications only and will not effect the sending and receiving of any transactional communications from Laybuy. Transactional communications are required communications in order to facilitate a transaction or provide a product or service and are non optional communications.
  </div>

  render = () => {
    const { formData, errors, onFormDataChange, preferencesContext } = this.props

    const invalidOrExpired = (preferencesContext?.errors?.show?.status === 401)
    const subText = invalidOrExpired ?
      <span>This link has expired, click <span style={{color: "#786dff", cursor: 'pointer'}} onClick={this.handleSendLinkClick}>here</span> and we'll email you a new link</span> :
      this.props.subText
    return (
      <div>
        <style type="text/css" dangerouslySetInnerHTML={{__html: `body { background-color: #786dff; }`}} />
        {this.renderUnsubDialog()}
        <div className={styles.header}>
          <div className={styles.inner}>
            <div className={styles.left}>
              <LaybuyHeart />
            </div>
            <div className={styles.right}>
              <div>{this.props.formData.email}</div>
              <div>{this.props.formData.phone}</div>
            </div>
            {this.state.successBanner && <div className={`${styles.banner} ${styles.success}`} onClick={this.clearBanners}>
              { this.state.successMessage || "Success! You've updated your communications preferences." }
            </div>}
            {this.state.errorBanner && <div className={`${styles.banner} ${styles.error}`} onClick={this.clearBanners}>
              { this.state.errorMessage || "Something went wrong, please try again..." }
            </div>}
          </div>
        </div>
        <div className={styles.container}>
          <PageContainer className={styles.card}>
            <Typography variant='h5' className={styles.mainHeading}>Communication Preferences</Typography>
            <p className={styles.text}>{subText}</p>
            { invalidOrExpired ? <div></div> : this.props.dependenciesMet ?
            <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={this.handleSave}>
              {(this.props.sections || []).map(({name: sectionName, fields}) => <>
                <Typography variant='h5' className={styles.subHeading}>{sectionName}</Typography>
                {fields.map(({name: fieldName, label: fieldLabel}) =>
                  <LabeledCheckbox key={fieldName} type="checkbox" onChange={this.handleCheckChange} label={fieldLabel} name={fieldName} />
                )}
              </>)}

              <LabeledCheckbox type="checkbox" onChange={this.handleUnsubMasterChecked} label={this.props.unsubscribeText} name="unsubMaster" labelStyle={{ marginTop: '32px', marginBottom: '32px' }} />
              <Button color='secondary' fullWidth variant='contained' type='submit'>Update My Preferences</Button>
              {this.renderFinePrint()}
            </ControlledForm> :
            <div><CircularProgress /></div>}
          </PageContainer>
        </div>
        <div className={styles.footer}>
          <BackToTop className={styles.backToTop} onClick={() => window.scrollTo(0, 0)} />
        </div>
      </div>
    )
  }
}