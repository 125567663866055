import React from 'react'
import instanceForm from 'containers/shared/instanceForm'
import dependsOn from 'containers/shared/dependsOn'
import { compose } from 'utils'
import { provide, CustomerPreferencesContext } from 'contexts'
import BaseForm from '../base_preferences/Form'
import qs from 'qs'

const sections = [
  {
    name: "Emails",
    fields: [
      { name: 'emailInspo', label: "Newsletter & Shopping Inspo" },
      { name: 'emailGeneral', label: "General Updates" },
      { name: 'emailPromos', label: "Competitions & Promotions" },
      { name: 'emailMerchant', label: "New Merchant Announcements" }
    ]
  },
  {
    name: "SMS",
    fields: [
      { name: 'smsGeneral', label: "General Updates" },
    ]
  },
  {
    name: "App Notifications",
    fields: [
      { name: 'pushPayments', label: "Payment Reminders" },
      { name: 'pushGeneral', label: "General Updates" },
      { name: 'pushPromos', label: "Offers & Promotions" },
      { name: 'pushMerchants', label: "New Merchant Announcements" }
    ]
  },
]

export const Form = ({ customerPreferences, ...props }) =>
  <BaseForm
    preferencesContext={customerPreferences}
    modelType={'customerPreferences'}
    sections={sections}
    subText={"Let us know which emails, notifications and texts you'd like to see."}
    unsubscribeText={"Unsubscribe me from all marketing communications"}
    {...props} />

const fetchDependencies = async ({ customerPreferences, id, location }) => {
  if (id) {
    return await customerPreferences.actions.show({ id })
  } else {
    try {
      const { email, signature, timestamp } = qs.parse(location.search, { ignoreQueryPrefix: true })
      return await customerPreferences.actions.show({ email, signature, timestamp })
    } catch {}
  }
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('customerPreferences'),
  provide(CustomerPreferencesContext),
)(Form)