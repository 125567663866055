import APIContext from 'contexts/base/APIContext'

export default class CustomerPreferencesContext extends APIContext{
  static contextName = 'customerPreferences'

  show = async(attributes, {fields=null, include=null }={}) => {
    const { data: selected } = await this.performRequest('show')({...attributes, options: { ...fields && {fields}, ...include && {include} }})
    const { email, signature, timestamp } = attributes
    this.setState({selected: { signature, timestamp, ...selected, email: (email || selected?.email) }, list: this.replace(this.state.list, selected)})
    return selected
  }

  update = async(item, options={}) => {
    const { data: selected }  = await this.performRequest('update')({...item, source: 'Preference Centre', options})
    const { email, signature, timestamp } = item
    this.setState({selected: { signature, timestamp, ...selected, email: (email || selected?.email) }, list: this.replace(this.state.list, selected)})
    return selected
  }

  sendLink = async(attributes, options={}) => {
    await this.performRequest('sendLink')({ ...attributes, options})
  }

  generateLink = async(attributes, options={}) => {
    return await this.performRequest('generateLink')({ ...attributes, options})
  }

  upload = async(item, {...options}={}) => {
    const { data: result } = await this.performRequest('upload')({...item, options})
    return result
  }
}
