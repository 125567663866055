import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      dark: '#544cb2',
      main: "#786dff",
      light: '#938aff',
      contrastText: '#FFF'
    },
    secondary: {
      dark: "#3c645f",
      main: "#4eb5ab",
      light: "#7bcdc5",
      contrastText: '#FFF'
    },
    type: 'light'
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'CircularStd, sans-serif',
  },
  overrides: {
    MuiPaper: {
      root: {
        '& .link-small': {
          margin: 5,
          textAlign: 'center',
          display: 'block',
          color: '#666565',
          fontSize: '0.8rem',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      },
      rounded: {
        borderRadius: '16px'
      }
    },
    MuiCardContent: {
      root: {
        maxWidth: 1000,
        margin: '0 auto',
        width: '100%',
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: '10px'
      }
    },
    MuiButton: {
      root: {
        marginBottom: '10px',
        '&+$root': {
          marginLeft: '10px'
        }
      }
    },
    MuiCheckbox: {
      root: {
        padding: '5px'
      },
      colorSecondary: {
        '&$checked': {
          color: '#786dff',
        },
      }
    }
  }
})