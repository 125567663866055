import React, { Component } from 'react'
import {Pagination, FABFixed, ErrorBanner, PageContainer} from 'components'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography'
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import indexView, {hasIndexChanged} from 'containers/shared/indexView'
import { UsersContext, SnackbarContext, provide, consume } from 'contexts'
import dependsOn from 'containers/shared/dependsOn'

export class List extends Component{

  showUser = id => () => {
    this.props.history.push(`/users/${id}`)
  }

  editUser = id => event => {
    this.props.history.push(`/users/${id}/edit`)
    event.stopPropagation()
  }

  deleteUser = id => event => {
    this.props.users.actions.destroy({id})
                .then(this.props.onDependencyUpdate)
                .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get users(){
    return this.props.users.list || []
  }

  get errors(){
    let errors = []
    if(this.props.users.errors.index){
      errors = errors.concat(this.props.users.errors.index)
    }
    if(this.props.users.errors.destroy){
      errors = errors.concat(this.props.users.errors.destroy)
    }
    return errors
  }

  email(user) {
    if (user.unconfirmedEmail) {
      return `${user.unconfirmedEmail} (uncomfirmed)`
    }

    return user.email
  }

  renderUserListItem = ({id, name, ...user}) =>
    <ListItem button onClick={this.showUser(id)} key={id}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={name} secondary={this.email(user)}/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.editUser(id)}><EditIcon/></IconButton>
        <IconButton onClick={this.deleteUser(id)}><DeleteIcon/></IconButton>
      </ListItemSecondaryAction>
    </ListItem>

    renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>


  render = () =>
    <PageContainer>
      <Typography variant='h3' style={{ display: 'flex', marginBottom: 16 }}>Users</Typography>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.users.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <MuiList dense>
        {this.users.map(this.renderUserListItem)}
      </MuiList>
      <Pagination totalPages={this.props.users.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <FABFixed color='secondary' onClick={() => this.props.history.push('/users/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
}

const fetchDependencies = ({users, page}) => {
  return users.actions.index({
    page: page,
    fields: {users: 'name,email,unconfirmedEmail'}
  })
}

export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('users'),
  provide(UsersContext),
  consume(SnackbarContext)
)(List)