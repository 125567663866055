import React, { Component } from 'react'
import { Pagination, ErrorBanner, PageContainer, SearchCombo, withConfirm } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import EditIcon from '@material-ui/icons/Edit'
import LinkIcon from '@material-ui/icons/Link'
import DeleteIcon from '@material-ui/icons/Delete'
import CopyIcon from '@material-ui/icons/FileCopy'
import Typography from '@material-ui/core/Typography'
import { compose, errorStringsFromError, Authorization } from 'utils'
import indexView, { hasIndexChanged } from 'containers/shared/indexView'
import { provide, consume, SnackbarContext, CustomerPreferencesContext } from 'contexts'
import CustomerPreferenceIcon from '@material-ui/icons/Markunread'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import LeadFileUpload from './LeadFileUpload'

export class List extends Component {

  state = {
    page: 1
  }

  editCustomerPreference = id => event => {
    this.props.history.push(`/customer_preferences/${id}/edit`)
    event.stopPropagation()
  }

  get customerPreferences() {
    return this.props.customerPreferences.list
  }

  get errors() {
    let errors = []
    if (this.props.customerPreferences.errors.index) {
      errors = errors.concat(this.props.customerPreferences.errors.index)
    }
    if (this.props.customerPreferences.errors.destroy) {
      errors = errors.concat(this.props.customerPreferences.errors.destroy)
    }
    return errors
  }

  handleFilterChange = (filter) => {
    this.props.onFilterChange(filter, this.props.onDependencyUpdate)
  }

  generateLink = (email) => async (e) => {
    try {
      const result = await this.props.customerPreferences.actions.generateLink({ email })
      if (result?.data?.link) {
        this.setState({ generatedLink: result?.data?.link, generatedLinkEmail: result?.data?.email })
        return
      }
    } catch (e) { }
    this.props.snackbar.actions.show('Something went wrong, please try again.')
  }

  clearGeneratedLink = () =>
    this.setState({ generatedLink: undefined, generatedLinkEmail: undefined })

  deletePreference = (id, details) => async event => {
    event.stopPropagation()
    if (await this.props.confirm({ title: 'Confirm Preference Deletion', details })) {
      this.props.customerPreferences.actions.destroy({ id })
        .then(this.props.onDependencyUpdate)
        .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    }
  }

  renderCustomerPreferenceListItem = ({ id, email, phone, customerid }) =>
    <ListItem button onClick={this.editCustomerPreference(id)} key={id}>
      <ListItemIcon>
        <CustomerPreferenceIcon />
      </ListItemIcon>
      <ListItemText primary={email} secondary={<span>{'Phone: '}{phone}<br />{'Customer: '}{customerid}</span>} />
      <ListItemSecondaryAction>
        <IconButton onClick={this.generateLink(email)}><LinkIcon /></IconButton>
        {Authorization.admin && <IconButton onClick={this.deletePreference(id, {email, customerid})}><DeleteIcon /></IconButton>}
        <IconButton onClick={this.editCustomerPreference(id)}><EditIcon /></IconButton>
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderSearchCombo = () =>
    <SearchCombo onFilterChange={this.handleFilterChange} filter={this.props.filter} />

  renderGeneratedLink = () =>
    <Dialog open={!!this.state.generatedLink}>
      <DialogContent>
        Customer preferences link for <a style={{ color: '#786dff' }} href={this.state.generatedLink} rel="noopener noreferrer" target="_blank">
          {this.state.generatedLinkEmail}
        </a>
        <IconButton onClick={() => navigator.clipboard.writeText(this.state.generatedLink)}><CopyIcon /></IconButton>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' variant='contained' style={{ marginRight: '12px' }} onClick={this.clearGeneratedLink}>
          Close
        </Button>
      </DialogActions>
    </Dialog>

  render = () =>
    <PageContainer>
      <Typography variant='h3' style={{ display: 'flex', marginBottom: 16 }}>
        Customer Preferences
        <div style={{ flex: 1 }}></div>
        <LeadFileUpload snackbar={this.props.snackbar} preferences={this.props.customerPreferences} onDependencyUpdate={this.props.onDependencyUpdate} />
      </Typography>
      {this.renderErrorMessages()}
      {this.renderGeneratedLink()}
      <Pagination totalPages={this.props.customerPreferences.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}} startAdornment={this.renderSearchCombo()} />
      <MuiList dense>
        {this.customerPreferences.map(this.renderCustomerPreferenceListItem)}
      </MuiList>
      <Pagination totalPages={this.props.customerPreferences.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}} />
    </PageContainer>
}

const fetchDependencies = ({ customerPreferences, filter, page }) => {
  return customerPreferences.actions.index({
    pageSize: 8, page, filter,
    fields: { customerPreferences: 'email,phone,customerid' },
    sort: 'id'
  })
}

export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('customerPreferences'),
  withConfirm,
  provide(CustomerPreferencesContext),
  consume(SnackbarContext),
)(List)