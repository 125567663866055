import React from 'react'
import { ConfirmationDialog } from 'components'
import { humanize } from 'utils'

export const withConfirm = (WrappedComponent) => {
  return class extends React.Component {
    state = { dialogState: { open: false } }

    confirm = async (options = {}) => {
      const { renderBody, details, ...dialogProps } = options
      let resolve, confirmationPromise = new Promise(r => resolve = r)
      this.setState({dialogState: {
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
        confirmLabel: "Confirm",
        cancelLabel: "Cancel",
        open: true,
        ...dialogProps
      }, renderBody, details})
      const result = await confirmationPromise
      this.setState({dialogState: { open: false }})
      return result
    }

    render = () => <>
      <ConfirmationDialog title={'Confirm'} {...this.state.dialogState }>
        {this.state.renderBody && this.state.renderBody()}
        {this.state.details && <table><tbody>
          {Object.entries(this.state.details).map(([key,value]) =>
            <tr key={key}><th style={{textAlign: 'right'}}>{humanize(key)}:</th><td>{value}</td></tr>
          )}
        </tbody></table>}
      </ConfirmationDialog>
      <WrappedComponent confirm={this.confirm} {...this.props} />
    </>
  }
}

export default withConfirm