import { ProtectedJsonAPI } from './ProtectedJsonAPI'
import {Tokens as TokensBase, ApiPathError} from './generated'
export const Tokens = TokensBase.extend(
  '',
  {
    refresh: endpoint => endpoint.method('put').path('/tokens').paramsHandler(() => {}).headers((headers, auth) => {return {...headers, ...(auth ? {Authorization: auth, "X-Authorization": auth} : {}) }}),
  }
)

export const CustomerPreferences = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/customer_preferences'
    }),
    show: endpoint => endpoint.path(({id, email, timestamp, signature}) => {
      if (email) {
        return `/customer_preferences/${encodeURIComponent(email)}/${encodeURIComponent(timestamp)}/${encodeURIComponent(signature)}`
      }
      if (id) {
        return `/customer_preferences/${id}`
      }
      throw new ApiPathError('show', {id, email, timestamp, signature})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      return `/customer_preferences/${id}`
    }),
    sendLink: endpoint => endpoint.method('post').path(() => {
      return '/customer_preferences/send_link'
    }),
    generateLink: endpoint => endpoint.path(({email}) => {
      if (email) {
        return `/customer_preferences/generate_link/${email}`
      }
      throw new ApiPathError('generate_link', {email})
    }),
    upload: endpoint => endpoint.useFormData(true).method('post').path(() => {
      return '/customer_preferences/upload'
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/customer_preferences/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const MerchantPreferences = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/merchant_preferences'
    }),
    show: endpoint => endpoint.path(({id, email, timestamp, signature}) => {
      if (email) {
        return `/merchant_preferences/${encodeURIComponent(email)}/${encodeURIComponent(timestamp)}/${encodeURIComponent(signature)}`
      }
      if (id) {
        return `/merchant_preferences/${id}`
      }
      throw new ApiPathError('show', {id, email, timestamp, signature})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      return `/merchant_preferences/${id}`
    }),
    sendLink: endpoint => endpoint.method('post').path(() => {
      return '/merchant_preferences/send_link'
    }),
    generateLink: endpoint => endpoint.path(({email}) => {
      if (email) {
        return `/merchant_preferences/generate_link/${email}`
      }
      throw new ApiPathError('generate_link', {email})
    }),
    upload: endpoint => endpoint.useFormData(true).method('post').path(() => {
      return '/merchant_preferences/upload'
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/merchant_preferences/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export * from './generated'