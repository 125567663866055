// DO NOT edit this file, add extentions in index.js.
// Changes to this file will be overwritten when regenerating

import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export class ApiPathError extends Error {
  constructor(action, params) {
    super(`No route for ${action} matches the provided params: ${JSON.stringify(params)}`)
    this.action = action
    this.params = params
  }
}

export const API = ProtectedJsonAPI.extend(
  '',
  {
    routeNotFound: endpoint => endpoint.path(() => {
      return '/'
    }),
    tag: endpoint => endpoint.path(() => {
      return '/tag'
    }),
  }
)

export const Tokens = ProtectedJsonAPI.extend(
  '',
  {
    forgot: endpoint => endpoint.method('post').path(() => {
      return '/tokens/forgot'
    }),
    reset: endpoint => endpoint.method('post').path(() => {
      return '/tokens/reset'
    }),
    confirm: endpoint => endpoint.method('post').path(() => {
      return '/tokens/confirm'
    }),
    resendConfirmation: endpoint => endpoint.method('post').path(() => {
      return '/tokens/resend_confirmation'
    }),
    acceptInvite: endpoint => endpoint.method('post').path(() => {
      return '/tokens/accept_invite'
    }),
    update: endpoint => endpoint.method('put').path(() => {
      return '/tokens'
    }),
    destroy: endpoint => endpoint.method('delete').path(() => {
      return '/tokens'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/tokens'
    }),
  }
)

export const Users = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/users'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/users'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const CustomerPreferences = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/customer_preferences'
    }),
    show: endpoint => endpoint.path(({id, email, timestamp, signature}) => {
      if (email && timestamp && signature) {
        return `/customer_preferences/${email}/${timestamp}/${signature}`
      }
      if (id) {
        return `/customer_preferences/${id}`
      }
      throw new ApiPathError('show', {id, email, timestamp, signature})
    }),
    generateLink: endpoint => endpoint.path(({email}) => {
      if (email) {
        return `/customer_preferences/generate_link/${email}`
      }
      throw new ApiPathError('generate_link', {email})
    }),
    sendLink: endpoint => endpoint.method('post').path(() => {
      return '/customer_preferences/send_link'
    }),
    upload: endpoint => endpoint.method('post').path(() => {
      return '/customer_preferences/upload'
    }),
    update: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/customer_preferences(/${id})`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/customer_preferences/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const MerchantPreferences = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/merchant_preferences'
    }),
    show: endpoint => endpoint.path(({id, email, timestamp, signature}) => {
      if (email && timestamp && signature) {
        return `/merchant_preferences/${email}/${timestamp}/${signature}`
      }
      if (id) {
        return `/merchant_preferences/${id}`
      }
      throw new ApiPathError('show', {id, email, timestamp, signature})
    }),
    generateLink: endpoint => endpoint.path(({email}) => {
      if (email) {
        return `/merchant_preferences/generate_link/${email}`
      }
      throw new ApiPathError('generate_link', {email})
    }),
    sendLink: endpoint => endpoint.method('post').path(() => {
      return '/merchant_preferences/send_link'
    }),
    update: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/merchant_preferences(/${id})`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/merchant_preferences/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const FileUploads = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/file_uploads'
    }),
  }
)

export const Audits = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/audits'
    }),
  }
)
