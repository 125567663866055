import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AuditIcon from '@material-ui/icons/Security'
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import CustomerPreferenceIcon from '@material-ui/icons/Markunread'
import MerchantPreferenceIcon from '@material-ui/icons/Store'
import FileUploadIcon from '@material-ui/icons/CloudUpload'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose, Authorization  } from 'utils'
import withStyles from 'styles'
import { consume, provide, TokensContext, VersionsContext } from 'contexts'
import { LaybuyMono } from 'components'

export class Nav extends Component{

  state = { drawerOpen: false }

  componentDidMount(){
    this.props.versions.actions.getTag()
  }

  handleRequestOpen = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  }

  handleRequestClose = () => {
    this.setState({open: false})
  }

  render = () =>
    <Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Typography className={this.props.classes.title} variant="h6" color="inherit">
            <LaybuyMono />
            <Link to='/'>Preference Center</Link>
          </Typography>
          <IconButton color="inherit" aria-label="Menu" onClick={() => this.setState({drawerOpen: true})}>
            <MenuIcon/>
          </IconButton>
          <IconButton color="inherit" onClick={this.handleRequestOpen}>
            <PersonIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        open={!!this.state.open}
        onClose={this.handleRequestClose}
      >
        <Link to="/profile"><MenuItem>My Profile</MenuItem></Link>
        <MenuItem onClick={this.props.tokens.actions.destroy}>Logout</MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        classes={{paper: this.props.classes.drawer}}
        open={this.state.drawerOpen}
        onClose={() => this.setState({drawerOpen: false})}
        onClick={() => this.setState({drawerOpen: false})}
      >

        <Link to='/customer_preferences'>
          <ListItem button>
            <ListItemIcon>
              <CustomerPreferenceIcon />
            </ListItemIcon>
            <ListItemText primary="Customer Preferences" />
          </ListItem>
        </Link>
        <Link to='/merchant_preferences'>
          <ListItem button>
            <ListItemIcon>
              <MerchantPreferenceIcon />
            </ListItemIcon>
            <ListItemText primary="Merchant Preferences" />
          </ListItem>
        </Link>

        {
          Authorization.admin &&
          <Link to='/file_uploads'>
            <ListItem button>
              <ListItemIcon>
                <FileUploadIcon/>
              </ListItemIcon>
              <ListItemText primary="File Uploads" />
            </ListItem>
          </Link>
        }
        {
          Authorization.admin &&
          <Link to='/users'>
            <ListItem button>
              <ListItemIcon>
                <PeopleIcon/>
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </Link>
        }
        { Authorization.admin &&
          <Link to='/audits'>
            <ListItem button>
              <ListItemIcon>
                <AuditIcon />
              </ListItemIcon>
              <ListItemText primary="Audits" />
            </ListItem>
          </Link>
        }
        <div className={this.props.classes.version}>
          API {this.props.versions.tag} / CLIENT {process.env.REACT_APP_VERSION}
        </div>
      </Drawer>
    </Fragment>
}

const styles = theme => ({
  title: {
    flex: 1,
    '& > div': {
      top: 3,
      position: 'relative',
      display: 'inline-block',
      marginTop: 5,
      marginRight: 15
    }
  },
  drawer: {
    background: theme.palette.primary.dark,
    paddingTop: 10,
    '& a, & svg': {
      color: 'white',
      fill: 'white !important'
    },
    '& a > div': {
      paddingLeft: 30,
      paddingRight: 70
    }
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  }
})
export default compose(
  consume(TokensContext),
  provide(VersionsContext),
  withStyles(styles)
)(Nav)