import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import dependsOn from 'containers/shared/dependsOn'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { PageContainer } from 'components'
import { provide, CustomerPreferencesContext } from 'contexts'

export class Show extends Component{

  get customerPreference(){
    return this.props.customerPreferences.selected
  }

  render = () =>
    <PageContainer>
      <Typography variant='h4'>CustomerPreference - {this.customerPreference.name}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/customer_preferences/${this.customerPreference.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Email</TableCell>
              <TableCell>{String(this.customerPreference.email || '')}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
}

const fetchDependencies = ({customerPreferences,  match: { params: { id }}}) => {
  return customerPreferences.actions.show(id, {
    include: ''
  })
}

export default provide(CustomerPreferencesContext)(dependsOn(fetchDependencies)(Show))