import React from 'react'
import instanceForm from 'containers/shared/instanceForm'
import dependsOn from 'containers/shared/dependsOn'
import { compose } from 'utils'
import { provide, MerchantPreferencesContext } from 'contexts'
import BaseForm from '../base_preferences/Form'
import qs from 'qs'

const sections = [
  {
    name: "Emails",
    fields: [
      { name: 'emailGeneral', label: "General Updates" },
      { name: 'emailMarketing', label: "Marketing Updates" },
      { name: 'emailReporting', label: "Reporting Updates" }
    ]
  },
  {
    name: "SMS",
    fields: [
      { name: 'smsGeneral', label: "General Updates" },
    ]
  },
]

export const Form = ({ merchantPreferences, ...props }) =>
  <BaseForm
    preferencesContext={merchantPreferences}
    modelType={'merchantPreferences'}
    sections={sections}
    subText={"Let us know which emails and texts you’d like to see."}
    unsubscribeText={"Unsubscribe me from all communications"}
    {...props} />

const fetchDependencies = async ({ merchantPreferences, id, location }) => {
  if (id) {
    return await merchantPreferences.actions.show({ id })
  } else {
    try {
      const { email, signature, timestamp } = qs.parse(location.search, { ignoreQueryPrefix: true })
      return await merchantPreferences.actions.show({ email, signature, timestamp })
    } catch {}
  }
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('merchantPreferences'),
  provide(MerchantPreferencesContext),
)(Form)