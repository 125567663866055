import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GoogleSignInImage from 'imgs/btn_google_signin_light_normal_web.png'
import { GoogleAuthService } from '../../services'
import withStyles from 'styles'
import { compose } from 'utils'
import { consume, SnackbarContext } from 'contexts'


export class GoogleSignInButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onAuthorized: PropTypes.func.isRequired
  }

  authorizationCallback = async authHash => {
    let {error, errorDescription, authCode, redirectUri} = authHash

    if(error) {
      this.props.snackbar.actions.show(`Failed to retrieve Google authorization code. ${errorDescription}`)
    } else {
      this.props.snackbar.actions.show('Google authorization code retrieved.')
      await this.props.onAuthorized({ authCode, redirectUri })
    }
  }

  authorize = () => {
    GoogleAuthService.authorize(this.authorizationCallback)
  }

  render = () => {
    return(
      <img  onClick={this.authorize} alt={this.props.label} src={GoogleSignInImage}/>
    )
  }
}

const styles = (theme) =>({
  loginButton: {
  },
})

export default compose(
  withStyles(styles),
  consume(SnackbarContext),
)(GoogleSignInButton)