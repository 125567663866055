import React, { Component } from 'react'
import { Pagination, ErrorBanner, PageContainer } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import FileUploadIcon from '@material-ui/icons/CloudUpload'
import { compose, errorStringsFromError, formatLocaleDateTime } from 'utils'
import indexView, {hasIndexChanged} from 'containers/shared/indexView'
import { provide, consume, SnackbarContext, FileUploadsContext } from 'contexts'

export class List extends Component{

  state = {
    page: 1
  }

  showFileUpload = id => () => {
    this.props.history.push(`/file_uploads/${id}`)
  }

  editFileUpload = id => event => {
    this.props.history.push(`/file_uploads/${id}/edit`)
    event.stopPropagation()
  }

  deleteFileUpload = id => event => {
    this.props.fileUploads.actions.destroy({id})
                .then(this.props.onDependencyUpdate)
                .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get fileUploads(){
    return this.props.fileUploads.list
  }

  get errors(){
    let errors = []
    if(this.props.fileUploads.errors.index){
      errors = errors.concat(this.props.fileUploads.errors.index)
    }
    if(this.props.fileUploads.errors.destroy){
      errors = errors.concat(this.props.fileUploads.errors.destroy)
    }
    return errors
  }

  statusColors = { "Success": "green", "Error": "red", "Undone": "#2E86C1" }

  renderFileDescription = ({ filename, status, user, createdAt }) =>
    <table style={{color: '#555', textAlign: 'left'}}>
      <tr><th>File Name:</th><td colspan="3">{filename}</td></tr>
      <tr><th>Status:</th><td style={{color: this.statusColors[status]}}>{status}</td></tr>
      <tr>
        <th>Uploaded By:</th><td>{user?.name} at {formatLocaleDateTime(createdAt)}</td>
      </tr>
    </table>

  renderFileUploadListItem = (fileUpload) => {
    const { id, fileUrl } = fileUpload
    return (
      <ListItem button key={id} style={{borderBottom: 'solid 1px #CCC'}}>
        <ListItemIcon>
          <FileUploadIcon />
        </ListItemIcon>
        {this.renderFileDescription(fileUpload)}
        <ListItemSecondaryAction>
          <IconButton onClick={() => window.open(fileUrl, "_blank")}><DownloadIcon /></IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      <Typography variant='h3' style={{ display: 'flex', marginBottom: 16 }}>File Uploads</Typography>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.fileUploads.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <MuiList dense>
        {this.fileUploads.map(this.renderFileUploadListItem)}
      </MuiList>
      <Pagination totalPages={this.props.fileUploads.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
    </PageContainer>
}

const fetchDependencies = ({fileUploads, page}) => {
  return fileUploads.actions.index({
    page: page,
    pageSize: 8,
    include: 'user',
    sort: '-created_at'
  })
}

export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('fileUploads'),
  provide(FileUploadsContext),
  consume(SnackbarContext),
)(List)