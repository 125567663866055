import React, { useState, useRef } from 'react'
import { ConfirmationDialog, LabeledCheckbox } from 'components'
import Tooltip from '@material-ui/core/Tooltip'
import UploadIcon from '@material-ui/icons/CloudUpload'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function LeadFileUpload({preferences, snackbar, onDependencyUpdate, onError, tooltip }) {
  const fileInputRef = useRef(null)
  const [confirmUploadOpen, setConfirmUploadOpen] = useState(false)
  const [fileToUpload, setFileToUpload] = useState(undefined)
  const [uploading, setUploading] = useState(false)
  const [legalConfirmation, setLegalConfirmation] = useState(false)

  const clearState = () => {
    setConfirmUploadOpen(false)
    setFileToUpload(undefined)
    setLegalConfirmation(false)
  }

  const handleToggleFileInput = () => {
    fileInputRef.current.click()
  }

  const handleFileDrop = async event => {
    event.preventDefault();
    event.stopPropagation()
    const file = event.dataTransfer.items ? event.dataTransfer.items[0].getAsFile() : event.dataTransfer.files[0]
    setConfirmUploadOpen(true)
    setFileToUpload(file)
  }

  const handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleFileSelected = ({ target: { files: { "0": file } } }) => {
    fileInputRef.current.value = null
    setConfirmUploadOpen(true)
    setFileToUpload(file)
  }

  const handleUploadConfirm = () => {
    handleFileUpload(fileToUpload)
    clearState()
  }

  const handleFileUpload = async (file) => {
    setUploading(true)
    const result = await preferences.actions.upload({ file: file })
    const message = result.status === "Started" ? "Upload started, you will be emailed on completion" : result.status === "Success" ? "Uploaded Successfully" : "Error Uploading"
    snackbar.actions.show(message)
    if (result.status === "Success") { onDependencyUpdate && onDependencyUpdate() }
    setUploading(false)
    onError && onError(result?.errors)
  }

  return <>
    <input style={{ display: 'none' }} type='file' ref={fileInputRef} onChange={handleFileSelected} />
    {uploading ?
      <CircularProgress /> :
      <Tooltip title={tooltip || 'Upload leads'}>
        <IconButton onClick={handleToggleFileInput} onDrop={handleFileDrop} onDragOver={handleDragOver}>
          <UploadIcon />
        </IconButton>
      </Tooltip>
    }
    <ConfirmationDialog title="Confirm Upload"
      open={confirmUploadOpen}
      onConfirm={handleUploadConfirm}
      canConfirm={legalConfirmation}
      onCancel={clearState}
      confirmLabel="Confirm"
      cancelLabel="Cancel">
      <LabeledCheckbox
        onChange={ ({target: {checked}}) => setLegalConfirmation(checked) }
        label="I hereby confirm that all contacts in the attached file have consented to receiving marketing communications, in accordance with all applicable local and international laws."
      />
    </ConfirmationDialog>
  </>
}